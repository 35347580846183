/* Header start */

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 130px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease 0s, backdrop-filter 0.3s ease 0s, box-shadow 0.3s ease 0s;
  z-index: 50;

  &.fixed {
    background-color: rgba(255, 255, 255, .9);
    backdrop-filter: blur(10px);
  }

  a {
    padding: 12px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1e1e1e;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTY4IiBoZWlnaHQ9IjUxIiB2aWV3Qm94PSIwIDAgMTY4IDUxIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZyBmaWx0ZXI9InVybCgjZmlsdGVyMF9iXzM4XzIxNSkiPgo8cmVjdCB3aWR0aD0iMTY3LjUiIGhlaWdodD0iNTEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMC4wMDAyNDQxNDEpIiBmaWxsPSIjMUUxRTFFIiBmaWxsLW9wYWNpdHk9IjAuMDYiLz4KPC9nPgo8ZGVmcz4KPGZpbHRlciBpZD0iZmlsdGVyMF9iXzM4XzIxNSIgeD0iLTQwIiB5PSItMzkuOTk5OCIgd2lkdGg9IjI0Ny41IiBoZWlnaHQ9IjEzMSIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPgo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPgo8ZmVHYXVzc2lhbkJsdXIgaW49IkJhY2tncm91bmRJbWFnZUZpeCIgc3RkRGV2aWF0aW9uPSIyMCIvPgo8ZmVDb21wb3NpdGUgaW4yPSJTb3VyY2VBbHBoYSIgb3BlcmF0b3I9ImluIiByZXN1bHQ9ImVmZmVjdDFfYmFja2dyb3VuZEJsdXJfMzhfMjE1Ii8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW49IlNvdXJjZUdyYXBoaWMiIGluMj0iZWZmZWN0MV9iYWNrZ3JvdW5kQmx1cl8zOF8yMTUiIHJlc3VsdD0ic2hhcGUiLz4KPC9maWx0ZXI+CjwvZGVmcz4KPC9zdmc+Cg==');
    background-size: 100%;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: normal;
    border-radius: 30px;
    letter-spacing: -0.4px;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
  }

  &.fixed a {
    background-color: transparent;
  }

  &.fixed .btn-language-menu ul a:not(.activeLanguage) {
    color: #1e1e1e;
  }

  #logo {
    padding-right: 30px;
  }

  #logo a {
    padding: 10px 20px;
    background-image: none;
  }

  .phone-number {
    margin-left: auto;
    min-width: auto;
    flex-shrink: 0;
    background-color: transparent;
    background-image: none;
  }
}

.header {

  &__wrap,
  &__menu-wrap,
  &__nav ul {
    display: flex;
    align-items: center;
  }

  &__wrap {
    justify-content: space-between;
  }

  .common-home &__menu-wrap {
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
  }

  &__language {
    margin-right: 48px;
  }
}

.btn-language-menu ul {
  display: flex;
  align-items: center;

  a {
    padding: 10px 20px;
    min-width: auto;
    color: #1e1e1e;
  }

  a:not(.activeLanguage) {
    color: #1e1e1e;
    background-color: transparent;
    background-image: none;
  }
}

/* Header end */