/* Common */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #fff;
  /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #f37a45;
  border-radius: 30px;
}

:focus-visible {
  outline: 0;
}

.custom-tooltip {
  --bs-tooltip-bg: #fff;
  --bs-tooltip-color: #f37a45;
  --bs-tooltip-font-size: 12px;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  box-sizing: border-box;
}
*::before, *::after {
  box-sizing: border-box;
}

html {
  width: 100vw;
  height: 100%;
}

body {
  width: 100%;
  color: #151515;
  background-color: #fff;
  color: #000;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  overflow-x: hidden;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
.lock body {
  overflow: hidden;
  touch-action: none;
  overscroll-behavior: none;
}

.wrapper {
  padding-top: 0;
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.container {
  max-width: 1720px;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

/* Card = START */
.row-cols-custom {
  margin-left: -8px;
  margin-right: -8px;
}

.card {
  border: 0;
  border-radius: 10px;
  overflow: hidden;
  transition: color 0.3s ease-out;
}
.card:hover .item-product-information::before {
  transform: scale(6);
}
.card:hover .item-product-information a, .card:hover .item-product-weight {
  color: #fff;
  transition: color 0.3s ease-out;
}
.card:not(:hover) .item-product-information a, .card:not(:hover) .item-product-weight {
  transition: color 0.3s ease-out;
}

.item-product-information {
  position: relative;
  height: 100%;
  padding: 8px 30px 15px 11px;
  overflow: hidden;
}
.item-product-information::before {
  content: "";
  position: absolute;
  right: -63px;
  bottom: -72px;
  width: 116px;
  height: 116px;
  background-color: #f37a45;
  border-radius: 50%;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.3s ease-out;
}

.card-title,
.item-product-weight {
  position: relative;
}

.card-title {
  margin-bottom: 6px;
  min-height: 57px;
}
.card-title a {
  color: #7b544e;
  font-family: "Heuristica", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: normal;
}

.go-arrow {
  position: absolute;
  right: 14px;
  bottom: 14px;
  width: 11px;
  height: 11px;
}

.item-product-weight {
  color: #8c8c8c;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
}

.product-grid {
  display: grid;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  overflow-y: hidden;
}
.product-grid .card {
  margin-left: 0;
  margin-right: 2px;
}

.item-product-image {
  height: 300px;
  flex-shrink: 0;
}
.item-product-image a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Card = END */
.phone {
  white-space: nowrap;
}

.hidden {
  display: none;
}

.text-center {
  text-align: center;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.right {
  margin-left: auto;
}

small {
  font-size: 70%;
}

.form-control:focus {
  border-color: rgb(251, 194, 235);
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

label {
  user-select: none;
}

textarea {
  resize: vertical;
}

select {
  user-select: none;
  cursor: pointer;
}

.modal {
  padding: 20px;
}
.modal.in .modal-dialog {
  top: calc(50% - 83px);
  transform: translateY(-50%);
}

.modal-content {
  padding: 20px;
}

.modal-header .close {
  margin-top: -35px;
  margin-right: -35px;
  padding: 10px;
  width: 50px;
  height: 50px;
  font-size: 30px;
}

.modal-title {
  color: #000;
  font-family: "Craftwork Grotesk", sans-serif;
  font-weight: 700;
  font-size: 32px;
}

#error-not-found h1,
#error-not-found p {
  margin-bottom: 15px;
}
#error-not-found .delivery__btn {
  margin-top: 20px;
  margin-bottom: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* margin: 0; */
  font-weight: inherit;
  font-size: inherit;
}

/* Override the bootstrap defaults */
h1 {
  font-size: 48px;
}

h2 {
  font-size: 27px;
}

h3 {
  font-size: 21px;
}

h4 {
  font-size: 15px;
}

h5 {
  font-size: 12px;
}

h6 {
  font-size: 10.2px;
}

img {
  height: auto;
  max-width: 100%;
  display: block;
  border: none;
}

hgroup,
ul,
ol,
dd,
figure,
pre,
table,
fieldset,
hr {
  margin: 0;
}

address {
  margin-bottom: 0;
}

ul li {
  list-style: none;
}

ol,
ul {
  padding-left: 0;
}

button {
  cursor: pointer;
}

a {
  color: #7b544e;
  text-decoration: none;
  transition: color 0.3s ease 0s;
  /* text-decoration-skip-ink: auto; */
}
a:hover, a:link, a:visited {
  text-decoration: none;
}
a:focus {
  outline: 0;
}
a:active {
  outline: none;
}
a:hover {
  color: #f37a45;
  text-decoration: none;
}

/* Breadcrumb */
.breadcrumb {
  position: relative;
  margin: 8px 0;
  padding: 8px 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: end;
  align-items: center;
  overflow: auto;
  z-index: 1;
}
.breadcrumb > li:not(:last-child) {
  padding-right: 18px;
}
.breadcrumb > li {
  position: relative;
  list-style-type: none;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.breadcrumb > li + li::before {
  content: "";
  padding: 0;
}
.breadcrumb > li:not(:last-child)::after {
  content: "/";
  position: absolute;
  top: -5px;
  right: 11px;
  width: 0;
  height: 0;
  display: block;
  color: #8c8c8c;
}
.breadcrumb > li a {
  color: #8c8c8c;
  font-family: "Manrope", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 1;
}
.breadcrumb > li a:hover {
  color: #000;
}
.breadcrumb::-webkit-scrollbar {
  height: 2px;
}
.breadcrumb::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

/* content */
#content {
  min-height: 600px;
}

/* Cart */
.mini-cart {
  position: relative;
}

#cart {
  position: relative;
  user-select: none;
  cursor: pointer;
  z-index: 1;
}

#cart-total {
  position: absolute;
  top: -7px;
  right: -8px;
  width: 15px;
  height: 15px;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #7d965e;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 1.1;
  text-transform: uppercase;
  border-radius: 50%;
}

.container-cart {
  display: none;
  position: absolute;
  top: 60px;
  right: 0;
  width: 300px;
  color: #000;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 80px 22px 0px rgba(0, 0, 0, 0), 0px 51px 20px 0px rgba(0, 0, 0, 0.01), 0px 29px 17px 0px rgba(0, 0, 0, 0.05), 0px 13px 13px 0px rgba(0, 0, 0, 0.09), 0px 3px 7px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: 1s;
  cursor: auto;
}
.container-cart.in {
  display: block;
  height: auto;
  transition: 1s;
  animation: fadeIn 0.5s ease forwards;
}
@keyframes fadeIn {
  from {
    padding: 0px 15px;
    opacity: 0;
  }
  to {
    padding: 32px 15px;
    opacity: 1;
  }
}

.title-mini-cart {
  margin-bottom: 29px;
  color: #222b45;
  font-family: "Craftwork Grotesk", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 1;
}

.list-product-cart {
  padding-right: 10px;
  max-height: 50vh;
  overflow: auto;
}

.item-product-cart {
  width: 100%;
  display: flex;
  margin-bottom: 14px;
  padding-bottom: 14px;
  border-bottom: 1px solid #edf1f7;
}

.information-product-cart {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.top-product-cart {
  width: 100%;
  display: flex;
}

.bottom-product-cart {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bottom-product-cart .total-product {
  color: #222b45;
  font-family: "Craftwork Grotesk", sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 1.3;
}

.image-product-cart {
  margin-right: 12px;
  min-width: 80px;
  width: 80px;
  border-radius: 5px;
  overflow: hidden;
}

.delete-product {
  margin-left: auto;
}
.delete-product button {
  padding: 7.5px 12px;
  min-height: auto;
  background-color: transparent;
  cursor: pointer;
}

.name-product {
  margin-top: 17px;
  color: #0d0802;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: normal;
}
.name-product a {
  display: block;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  text-align: left;
}

.item-totals.total {
  margin-top: 15px;
  margin-bottom: 29px;
  display: flex;
  justify-content: space-between;
}
.item-totals .name-total {
  color: #222b45;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
}
.item-totals .value-total {
  color: #222b45;
  font-family: "Craftwork Grotesk", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 1;
}

.group-cart-button {
  display: flex;
  flex-direction: column;
  user-select: none;
}
.group-cart-button a.btn-checkout {
  margin-bottom: 22px;
  height: 55px;
  color: #fff;
  border-radius: 27.5px;
  background: linear-gradient(90deg, #F6D365 0%, #FDA085 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: normal;
  text-transform: none;
  -webkit-transition: color 0.2s ease 0s, background-color 0.2s ease 0s;
  -o-transition: color 0.2s ease 0s, background-color 0.2s ease 0s;
  transition: color 0.2s ease 0s, background-color 0.2s ease 0s;
}
.group-cart-button a.btn-checkout:hover {
  color: #fff;
}
.group-cart-button button[type=button] {
  color: #222B45;
  -webkit-text-fill-color: #222B45;
}
.group-cart-button button.btn-exit {
  font-size: 16px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.quantity-product .qty-spinedit {
  display: flex;
}
.quantity-product .qty-spinedit .quantity {
  color: #c5cee0;
  text-align: center;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.4;
  background-color: transparent;
}
.quantity-product .quantity {
  border: none;
  text-align: center;
}
.quantity-product input.qty-minus, .quantity-product input.qty-plus {
  width: 30px;
  height: 30px;
  border-radius: 11px;
  color: #8992a3;
  background-color: transparent;
  font-size: 24px;
  line-height: 1;
  border: 1px solid #edf1f7;
  cursor: pointer;
}

/* Pagination */
.pagination {
  margin: 0;
  border-radius: 0;
  justify-content: end;
}
.pagination > li.text_first a, .pagination > li.text_last a, .pagination > li.text_prev a, .pagination > li.text_next a {
  color: #121212;
  background-color: transparent;
  font-size: 22px;
}
.pagination > li > a, .pagination > li > span {
  margin-left: 7px;
  margin-right: 7px;
  margin-bottom: 12px;
  min-width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #121212;
  background-color: #ededed;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 10px;
  user-select: none;
  transition: background-color 0.3s ease 0s, color 0.3s ease 0s;
}
.pagination > li:last-child > a, .pagination > li:last-child > span {
  border-radius: 10px;
}
.pagination > li:first-child > a, .pagination > li:first-child > span {
  border-radius: 10px;
}
.pagination > .active > a {
  color: #fff;
  background-color: #f37a45;
  border-color: transparent;
  cursor: default;
}
.pagination > .active > a:focus, .pagination > .active > a:hover {
  color: #fff;
  background-color: #f37a45;
  border-color: transparent;
  cursor: default;
}
.pagination > .active > span {
  color: #fff;
  background-color: #f37a45;
  border-color: transparent;
  cursor: default;
}
.pagination > .active > span:focus, .pagination > .active > span:hover {
  color: #fff;
  background-color: #f37a45;
  border-color: transparent;
  cursor: default;
}
.pagination > li > a:focus, .pagination > li > a:hover {
  color: #fff;
  background-color: #f37a45;
}
.pagination > li > span:focus, .pagination > li > span:hover {
  color: #fff;
  background-color: #f37a45;
}
.pagination_block {
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
}
.pagination_block::-webkit-scrollbar {
  height: 2px;
}

/* Swiper */
.swiper-pagination {
  position: relative;
  margin-top: 39px;
  text-align: center;
}
.swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 2px;
}
.swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background: #ffe9da;
  opacity: 1;
}
.swiper-pagination .swiper-pagination-bullet-active {
  background: #f37a45;
}

/* Button start */
.btn {
  padding: 40px 20px;
  height: 92px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #151515;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.2;
  border-radius: 26px;
  border: 1px solid #151515;
  word-wrap: break-word;
  white-space: nowrap;
  user-select: none;
  overflow: hidden;
  cursor: pointer;
}
.btn:hover {
  color: #151515;
  background-color: transparent;
  border-color: #151515;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  color: #151515;
  background-color: transparent;
  border-color: #151515;
}

/* Button end */
/* Header start */
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 130px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease 0s, backdrop-filter 0.3s ease 0s, box-shadow 0.3s ease 0s;
  z-index: 50;
}
header.fixed {
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
}
header a {
  padding: 12px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1e1e1e;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTY4IiBoZWlnaHQ9IjUxIiB2aWV3Qm94PSIwIDAgMTY4IDUxIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZyBmaWx0ZXI9InVybCgjZmlsdGVyMF9iXzM4XzIxNSkiPgo8cmVjdCB3aWR0aD0iMTY3LjUiIGhlaWdodD0iNTEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMC4wMDAyNDQxNDEpIiBmaWxsPSIjMUUxRTFFIiBmaWxsLW9wYWNpdHk9IjAuMDYiLz4KPC9nPgo8ZGVmcz4KPGZpbHRlciBpZD0iZmlsdGVyMF9iXzM4XzIxNSIgeD0iLTQwIiB5PSItMzkuOTk5OCIgd2lkdGg9IjI0Ny41IiBoZWlnaHQ9IjEzMSIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPgo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPgo8ZmVHYXVzc2lhbkJsdXIgaW49IkJhY2tncm91bmRJbWFnZUZpeCIgc3RkRGV2aWF0aW9uPSIyMCIvPgo8ZmVDb21wb3NpdGUgaW4yPSJTb3VyY2VBbHBoYSIgb3BlcmF0b3I9ImluIiByZXN1bHQ9ImVmZmVjdDFfYmFja2dyb3VuZEJsdXJfMzhfMjE1Ii8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW49IlNvdXJjZUdyYXBoaWMiIGluMj0iZWZmZWN0MV9iYWNrZ3JvdW5kQmx1cl8zOF8yMTUiIHJlc3VsdD0ic2hhcGUiLz4KPC9maWx0ZXI+CjwvZGVmcz4KPC9zdmc+Cg==");
  background-size: 100%;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: normal;
  border-radius: 30px;
  letter-spacing: -0.4px;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
}
header.fixed a {
  background-color: transparent;
}
header.fixed .btn-language-menu ul a:not(.activeLanguage) {
  color: #1e1e1e;
}
header #logo {
  padding-right: 30px;
}
header #logo a {
  padding: 10px 20px;
  background-image: none;
}
header .phone-number {
  margin-left: auto;
  min-width: auto;
  flex-shrink: 0;
  background-color: transparent;
  background-image: none;
}

.header__wrap, .header__menu-wrap, .header__nav ul {
  display: flex;
  align-items: center;
}
.header__wrap {
  justify-content: space-between;
}
.common-home .header__menu-wrap {
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
}
.header__language {
  margin-right: 48px;
}

.btn-language-menu ul {
  display: flex;
  align-items: center;
}
.btn-language-menu ul a {
  padding: 10px 20px;
  min-width: auto;
  color: #1e1e1e;
}
.btn-language-menu ul a:not(.activeLanguage) {
  color: #1e1e1e;
  background-color: transparent;
  background-image: none;
}

/* Header end */
/* Footer start */
footer {
  padding-top: 30px;
  padding-bottom: 10px;
  flex: 1 1 auto;
  color: #fff;
  background-color: #141414;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3;
}
footer a {
  margin-top: 7px;
  margin-bottom: 7px;
  padding: 14px 40px;
  display: inline-block;
  color: #fff;
  background-color: #3d3d3d;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3;
  border-radius: 134px;
  white-space: nowrap;
  overflow: hidden;
  -webkit-transition: color 0.3s ease 0s, background-color 0.3s ease 0s;
  -o-transition: color 0.3s ease 0s, background-color 0.3s ease 0s;
  transition: color 0.3s ease 0s, background-color 0.3s ease 0s;
}
footer a:hover {
  color: #fff;
  background-color: #7e7e7e;
}
footer .phone {
  line-height: 1.4;
}

.footer-logo {
  margin-top: 120px;
  margin-bottom: 80px;
}

.footer__row {
  padding-top: 40px;
  padding-bottom: 40px;
  border-top: 2px solid #525252;
}
.footer__row.bottom .footer__columns {
  padding-right: 55px;
  justify-content: space-between;
  color: #a9a9a9;
}
.footer__row.bottom a {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  display: unset;
  color: #a9a9a9;
  background-color: transparent;
  line-height: 1;
  border-radius: 0;
}
.footer__row.bottom a:hover {
  color: #fff;
  background-color: transparent;
}

.footer__columns {
  display: flex;
  align-items: center;
}
.footer__columns .footer__column:first-child {
  width: 200px;
  flex-shrink: 0;
}

.footer__column-title {
  margin-right: 40px;
  color: #a9a9a9;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3;
}
.footer__column a:not(:last-child) {
  margin-right: 14px;
}

.footer-messenger {
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style-type: none;
  user-select: none;
}
.footer-messenger .footer-item:not(:last-child) {
  margin-right: 14px;
}
.footer-messenger .footer-item a {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-messenger .footer-item a img {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.footer-messenger .footer-item a svg,
.footer-messenger .footer-item a img {
  margin-right: 14px;
}

/* Footer end */
@supports (overflow: clip) {
  .wrapper {
    overflow: clip;
  }
}
@media (min-width: 992px) {
  header .phone-number,
  .header__burger-btn,
  .header__burger-menu-wrap,
  .header__burger-menu__img-mob,
  .header__burger-menu__second-img-mob {
    display: none;
  }
}
@media (max-width: 1239.98px) {
  .btn {
    margin-top: 30px;
    padding: 20px 40px;
    max-width: 370px;
    width: 100%;
    height: 60px;
    font-size: 20px;
    border-radius: 100px;
  }
}
@media (max-width: 1199.98px) {
  .wrapper {
    padding-top: 0;
  }
  header {
    height: 70px;
  }
  header #logo img {
    max-width: 210px;
    width: 100%;
  }
  header a {
    padding: 12px 20px;
    font-size: 16px;
  }
  .header__language {
    margin-right: 20px;
  }
}
@media (max-width: 991.98px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
  .breadcrumb,
  .pagination {
    justify-content: unset;
  }
  .product-grid {
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
  }
  .pagination > li > a,
  .pagination > li > span {
    min-width: 32px;
    height: 32px;
    font-size: 14px;
  }
  header #logo {
    padding-right: 10px;
  }
  .header__burger-btn {
    flex-shrink: 0;
    background-color: transparent;
  }
  .header__burger-menu {
    position: fixed;
    top: 0;
    left: 0;
    max-width: 350px;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-flow: column;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
    overflow: auto;
    z-index: 110;
  }
  .header__burger-menu::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("/image/template/menu-burger-bg.png") no-repeat;
    background-position-x: center;
    background-position-y: bottom;
    user-select: none;
    pointer-events: none;
  }
  .header__burger-menu.active {
    transform: translateX(0);
  }
  .header__burger-close-wrap {
    margin-left: auto;
  }
  .header__burger-close {
    width: 46px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }
  .header__burger-menu-wrap.active {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
    display: block;
    transition: background 0.3s;
    z-index: 51;
  }
  .header__burger-logo {
    margin: 20px auto 40px;
  }
  .header__burger-menu__img-mob {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    user-select: none;
    pointer-events: none;
  }
  .header__burger-menu__second-img-mob {
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  .header__burger-menu__second-img-mob::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: url("/image/templates/cake-menu-bg.png") no-repeat;
    background-position-x: right;
    background-position-y: bottom;
    background-size: 100%;
    user-select: none;
    pointer-events: none;
  }
  .header__menu-wrap {
    flex-direction: column-reverse;
  }
  .header__nav ul {
    flex-direction: column;
  }
  .dropdown-menu.show {
    display: block;
    left: 0 !important;
  }
  .header__language {
    position: relative;
    margin-right: 0;
    z-index: 1;
  }
  .btn-language-menu ul a {
    padding: 10px 20px;
    color: #1e1e1e;
    font-family: "Manrope", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: normal;
    border-radius: 30px;
    backdrop-filter: blur(20px);
    letter-spacing: -0.4px;
    white-space: nowrap;
    overflow: hidden;
  }
  .btn-language-menu ul a.activeLanguage {
    background: rgba(30, 30, 30, 0.06);
  }
  .header__nav {
    position: relative;
    margin-bottom: 60px;
    z-index: 1;
  }
  .header__nav a {
    margin-top: 6px;
    margin-bottom: 6px;
    padding: 10px 20px;
    width: 200px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #1c355b;
    border-radius: 26px;
    font-family: "Manrope", sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
  }
  .header__nav .dropdown-menu {
    position: relative !important;
    top: 0 !important;
    padding: 0;
    transition: all 0.3s;
  }
  .header__nav .dropdown-menu a {
    background-color: #7399d3 !important;
  }
}
@media (max-width: 767.98px) {
  .header__nav .dropdown-menu {
    top: -58px !important;
  }
}
@media (max-width: 649.98px) {
  footer img {
    margin-left: auto;
    margin-right: auto;
  }
  footer a {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 1.7;
  }
  footer .footer__row:nth-of-type(2) .footer__columns {
    border-top: 0;
  }
  .footer-logo {
    margin-top: 0;
    margin-bottom: 0;
  }
  .footer__row {
    padding-top: 25px;
    padding-bottom: 0;
    border-top: 0;
  }
  .footer__columns {
    padding-top: 26px;
    flex-direction: column;
    align-items: flex-start;
    border-top: 1px solid #3D3D3D;
  }
  .footer__column-title {
    margin-right: 0;
  }
  .footer-messenger {
    margin: 0;
  }
  .footer__columns .footer__column:first-child {
    margin-bottom: 17px;
    width: 100%;
  }
  .footer__column a:not(:last-child) {
    margin-right: 7px;
  }
  .footer__row.bottom .footer__columns {
    padding-top: 38px;
    padding-right: 0;
    padding-bottom: 44px;
  }
  .footer-messenger .footer-item:not(:last-child) {
    margin-right: 7px;
  }
  .footer__row.bottom .footer__columns ul {
    margin-bottom: 13px;
  }
}
@media (max-width: 389.98px) {
  header a {
    font-size: 14px;
  }
  .header-informations li a {
    font-size: 14px;
  }
  footer a {
    font-size: 14px;
  }
}
@media (max-width: 349.98px) {
  header #logo img {
    max-width: 180px;
  }
}
/*--- Modules ---*/
/* Main Page */
.implementation {
  padding-top: 150px;
  padding-bottom: 150px;
  background-color: #f3f3f3;
}
.implementation__top {
  margin-bottom: 138px;
  display: flex;
  justify-content: space-between;
}
.implementation__title {
  max-width: 972px;
  color: #151515;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 80px;
  line-height: 1;
  letter-spacing: -2.4px;
}
.implementation__title svg {
  display: none;
}
.implementation__subtitle-wrap {
  display: flex;
}
.implementation__subtitle {
  margin-right: 102px;
  max-width: 308px;
  color: #151515;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.2;
}
.implementation__image {
  flex-shrink: 0;
}
.implementation__image-mob {
  display: none;
}
.implementation__bottom {
  display: flex;
}
.implementation__bottom-left, .implementation__bottom-right {
  color: #151515;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 32px;
  line-height: 1;
}
.implementation__bottom-left {
  width: 66%;
}
.implementation__items {
  display: flex;
  flex-wrap: wrap;
}
.implementation__items .item {
  padding: 60px;
  width: 50%;
  display: flex;
  align-items: center;
  border-top: 1px solid #c7c7c7;
  border-right: 1px solid #c7c7c7;
}
.implementation__items .item:nth-of-type(odd) {
  padding-left: 0;
}
.implementation__items .item-number {
  margin-right: 40px;
  padding-top: 7px;
  width: 110px;
  height: 110px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  color: #000;
  background-color: #e9e9e9;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  border-radius: 50%;
  white-space: nowrap;
  user-select: none;
  pointer-events: none;
  overflow: hidden;
}
.implementation__items .item-number span {
  margin: 0 2px 17px;
  font-weight: 700;
  font-size: 40px;
}
.implementation__bottom-right {
  padding-top: 60px;
  padding-left: 60px;
  width: 34%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-top: 1px solid #c7c7c7;
}
.implementation__bottom-number {
  margin-bottom: 90px;
  color: #000;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 48px;
  line-height: 1;
  white-space: nowrap;
}
.implementation__bottom-number span {
  color: #fd512f;
  font-weight: 700;
  font-size: 200px;
}
.implementation__bottom-description p {
  margin-bottom: 0;
}
.implementation .container {
  max-width: 1770px;
}

@media (max-width: 1599.98px) {
  .implementation__top {
    margin-bottom: 100px;
  }
  .implementation__items .item {
    padding: 30px;
  }
  .implementation__items .item-number {
    margin-right: 30px;
  }
  .implementation__items .title-item {
    font-size: 18px;
  }
  .implementation__bottom-right {
    padding-top: 30px;
    padding-left: 30px;
  }
  .implementation__bottom-number {
    margin-bottom: 40px;
    font-size: 34px;
  }
  .implementation__bottom-number span {
    font-size: 120px;
  }
}
@media (max-width: 1365.98px) {
  .implementation__title {
    max-width: 100%;
  }
  .implementation__subtitle {
    margin-right: 42px;
    font-size: 20px;
  }
}
@media (max-width: 991.98px) {
  .implementation {
    padding-top: 65px;
    padding-bottom: 45px;
    background-color: #fff;
  }
  .implementation__top {
    margin-bottom: 0;
    flex-direction: column;
  }
  .implementation__title-wrap {
    display: flex;
    justify-content: space-between;
  }
  .implementation__title {
    margin-bottom: 15px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
  }
  .implementation__title svg {
    margin-left: 20px;
    display: block;
    flex-shrink: 0;
  }
  .implementation__image {
    display: none;
  }
  .implementation__image-mob {
    display: block;
  }
  .implementation__subtitle-wrap {
    margin-bottom: 25px;
  }
  .implementation__subtitle {
    margin-right: 0;
  }
  .implementation__bottom {
    flex-direction: column;
  }
  .implementation__bottom-left {
    width: 100%;
  }
  .implementation__items .item {
    padding: 20px;
  }
  .implementation__items .item:nth-of-type(even) {
    border-right: 0;
  }
  .implementation__bottom-right {
    padding-left: 0;
    width: 100%;
    align-items: center;
  }
}
@media (max-width: 767.98px) {
  .implementation__title {
    font-size: 36px;
    letter-spacing: -1.08px;
  }
  .implementation__subtitle {
    font-size: 16px;
  }
  .implementation__bottom {
    flex-direction: column;
  }
  .implementation__bottom-left {
    width: 100%;
  }
  .implementation__items .item {
    width: 100%;
  }
  .implementation__items .item:nth-of-type(odd) {
    border-right: 0;
  }
  .implementation__items .item:nth-of-type(even) {
    padding-left: 0;
  }
  .implementation__items .title-item {
    font-size: 16px;
  }
  .implementation__bottom-right {
    padding-left: 0;
    width: 100%;
    align-items: center;
    text-align: center;
  }
  .implementation__bottom-number {
    margin-bottom: 27px;
    font-size: 16px;
  }
  .implementation__bottom-number span {
    font-size: 96px;
  }
}
.hits {
  position: relative;
  padding-top: 101px;
  padding-bottom: 198px;
  background-color: #1e1e1e;
}
.hits__top {
  margin-bottom: 73px;
  display: flex;
  justify-content: space-between;
}
.hits__top-left {
  display: flex;
}
.hits__top-left svg {
  margin: 71px 84px 0 40px;
  flex-shrink: 0;
}
.hits__title {
  margin-bottom: 26px;
  max-width: 600px;
  color: #fff;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 80px;
  line-height: 1.4;
  letter-spacing: -1.6px;
}
.hits__subtitle {
  color: #fff;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: -0.6px;
}
.hits__top-right {
  margin-left: auto;
  margin-right: auto;
  padding-left: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hits__right-total {
  margin-bottom: 54px;
  color: rgba(255, 255, 255, 0.6);
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.2;
}
.hits__right-values {
  display: flex;
}
.hits__right-col {
  margin-right: 99px;
}
.hits__right-value {
  color: #f3f3f3;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 48px;
  line-height: 1.2;
}
.hits__right-text {
  color: #fff;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.2;
}
.hits .container {
  position: relative;
  max-width: 1850px;
}
.hits .hits-logo {
  position: absolute;
  top: 136px;
  right: 0;
  user-select: none;
  pointer-events: none;
}
.hits .accordion-item {
  position: relative;
  padding: 0 40px 40px;
  background-color: transparent;
  border-top: 1px solid transparent;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  border-radius: 20px;
  overflow: hidden;
  --bs-accordion-active-bg: none;
}
.hits .accordion-item::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 100%;
  user-select: none;
  pointer-events: none;
  transition: opacity 0.3s ease 0s;
  opacity: 0;
}
.hits .accordion-item:nth-of-type(1)::after {
  background: url("/image/template/hits-SCM-bg.png") no-repeat;
}
.hits .accordion-item:nth-of-type(2)::after {
  background: url("/image/template/hits-AI-bg.png") no-repeat;
}
.hits .accordion-item.active::after,
.hits .accordion-item:hover::after {
  opacity: 1;
}
.hits .accordion-item.active {
  padding-top: 40px;
  border-top: 1px solid transparent;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
}
.hits .accordion-item:first-of-type {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.hits .accordion-item:last-of-type {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.hits .number-item {
  margin-right: 94px;
  color: #fff;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2;
}
.hits .accordion-header {
  position: relative;
  padding-top: 40px;
  display: flex;
  border-top: 1px solid #494949;
  z-index: 1;
}
.hits .accordion-item.active .accordion-header {
  padding-top: 0;
  border-top: 0;
}
.hits .accordion-button-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.hits .accordion-button-left {
  max-width: 635px;
  width: 100%;
}
.hits .accordion-buttons {
  margin: 22px -6px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.hits .accordion-buttons a {
  margin: 6px;
  padding: 9px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1e1e1e;
  background: linear-gradient(90deg, #89f7fe 0%, #66a6ff 100%);
  border-radius: 40px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  user-select: none;
  white-space: nowrap;
}
.hits .accordion-buttons .btn {
  margin: 6px;
  padding: 5px 24px 4px 8px;
  height: auto;
  color: #fff;
  border-radius: 40px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  user-select: none;
  white-space: nowrap;
}
.hits .accordion-buttons .btn svg {
  margin-right: 12px;
  flex-shrink: 0;
}
.hits .accordion-button-right {
  width: 100%;
  display: flex;
}
.hits .accordion-button {
  padding: 0 20px 0;
  background-color: transparent;
  user-select: none;
  box-shadow: none;
}
.hits .accordion-button::after {
  display: none;
}
.hits .accordion-item-circle {
  margin-left: auto;
  margin-right: auto;
  width: 190px;
  height: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: rgba(243, 243, 243, 0.1);
  font-family: "Manrope", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 1.2;
  border-radius: 50%;
  backdrop-filter: blur(50px);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  overflow: hidden;
}
.hits .accordion-button.collapsed .accordion-item-circle {
  background-color: #7e7e7e;
  backdrop-filter: none;
}
.hits .accordion-title {
  color: #fff;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 48px;
  line-height: 1.2;
  letter-spacing: -1.44px;
}
.hits .accordion-subtitle {
  color: #fff;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: -0.6px;
}
.hits .accordion-collapse {
  position: relative;
  padding-right: 169px;
  z-index: 1;
}
.hits .accordion-body {
  padding: 60px 0 0;
  color: rgba(255, 255, 255, 0.6);
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.2;
}
.hits .accordion-body ul,
.hits .accordion-body ol {
  margin-left: 40px;
}
.hits .accordion-body ul li,
.hits .accordion-body ol li {
  list-style: disc;
}
.hits .accordion-body ol li:not(:last-child),
.hits .accordion-body ul li:not(:last-child) {
  margin-bottom: 10px;
}

@media (max-width: 1365.98px) {
  .hits__title {
    font-size: 60px;
  }
  .hits .accordion-title {
    font-size: 36px;
  }
  .hits .accordion-subtitle {
    font-size: 20px;
    letter-spacing: -0.48px;
  }
  .hits .accordion-buttons a,
  .hits .accordion-buttons .btn {
    font-size: 16px;
  }
  .hits .accordion-body {
    font-size: 16px;
  }
}
@media (max-width: 1049.98px) {
  .hits {
    padding-top: 39px;
    padding-bottom: 70px;
  }
  .hits__title {
    font-size: 36px;
    line-height: 1.1;
    letter-spacing: -0.72px;
  }
  .hits__top {
    margin-bottom: 26px;
  }
  .hits__top-right, .hits__top-left svg,
  .hits .number-item {
    display: none;
  }
  .hits .accordion-item,
  .hits .accordion-item.active {
    padding-top: 0;
    padding-left: 10px;
    padding-right: 10px;
  }
  .hits .accordion-item.active {
    padding-bottom: 20px;
    border-top: 0;
  }
  .hits .accordion-item {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 0;
    border: 0;
    border-radius: 0;
    overflow: unset;
  }
  .hits .accordion-item-circle {
    margin-right: unset;
    width: 100px;
    height: 100px;
    font-size: 14px;
  }
  .hits .accordion-item:first-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .hits .accordion-item:last-of-type {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .hits .accordion-item::after {
    height: 280px;
    background-size: cover;
    background-position: center;
  }
  .hits .accordion-item:nth-of-type(1)::after,
  .hits .accordion-item:nth-of-type(2)::after {
    background-size: cover;
    background-position: center;
  }
  .hits .accordion-header,
  .hits .accordion-item.active .accordion-header {
    margin-bottom: 60px;
    padding-top: 79px;
    padding-bottom: 32px;
  }
  .hits .accordion-title {
    font-size: 24px;
    letter-spacing: -0.72px;
  }
  .hits .accordion-subtitle {
    font-size: 16px;
  }
  .hits .accordion-body {
    padding-top: 0;
  }
  .hits .accordion {
    margin-left: -10px;
    margin-right: -10px;
  }
  .hits .accordion-collapse {
    padding-right: 0;
  }
  .hits .accordion-buttons {
    flex-direction: column;
    align-items: flex-start;
  }
  .hits .accordion-buttons .btn {
    max-width: 270px;
  }
  .hits .accordion-button {
    position: absolute;
    right: -18px;
    bottom: -38px;
  }
  .hits .accordion-button-left {
    padding-right: 100px;
    max-width: 100%;
  }
  .hits .accordion-button-right {
    width: auto;
  }
}
@media (max-width: 687.98px) {
  .hits .accordion-item::after {
    height: 305px;
  }
}
@media (max-width: 436.98px) {
  .hits .accordion-item::after {
    height: 330px;
  }
}
@media (max-width: 349.98px) {
  .hits .accordion-buttons a,
  .hits .accordion-buttons .btn {
    height: 44px;
  }
  .hits .accordion-buttons .btn {
    padding: 5px 8px;
  }
  .hits .accordion-buttons .btn svg {
    display: none;
  }
}
.why_us {
  padding-top: 150px;
  padding-bottom: 150px;
  background-color: #151515;
}
.why_us__wrap {
  display: flex;
}
.why_us__left {
  margin-right: 115px;
  max-width: 436px;
  flex-shrink: 0;
}
.why_us__title {
  margin-bottom: 30px;
  color: #fff;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  font-size: 64px;
  line-height: 1.2;
  letter-spacing: -1.28px;
}
.why_us__subtitle {
  margin-bottom: 91px;
  color: #f3f3f3;
  font-family: "Manrope", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 1.2;
}
.why_us__first-description, .why_us__second-description {
  display: flex;
  color: #f3f3f3;
  font-family: "Manrope", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
}
.why_us__first-description {
  margin-bottom: 40px;
}
.why_us__description-number {
  margin-right: 17px;
  color: #fff;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  font-size: 20px;
  line-height: 1.2;
}
.why_us__items {
  display: flex;
  flex-wrap: wrap;
}
.why_us__items .item {
  padding: 60px;
  width: 50%;
  display: flex;
  align-items: center;
  border-top: 1px solid #363636;
  border-right: 1px solid #363636;
}
.why_us__items .item:nth-of-type(odd) {
  padding-left: 0;
}
.why_us__items .item:nth-of-type(even) {
  border-right: 0;
}
.why_us__items .item-image {
  margin-right: 40px;
  width: 110px;
  height: 110px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  background-color: #262626;
  border-radius: 50%;
  user-select: none;
  pointer-events: none;
  overflow: hidden;
}
.why_us__items .title-item {
  color: #fff;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
}
.why_us .container {
  max-width: 1770px;
}

@media (max-width: 1599.98px) {
  .why_us__left {
    margin-right: 75px;
  }
  .why_us__title {
    font-size: 54px;
  }
  .why_us__subtitle {
    margin-bottom: 48px;
    font-size: 26px;
  }
  .why_us__first-description, .why_us__second-description {
    font-size: 18px;
  }
  .why_us__first-description {
    margin-bottom: 34px;
  }
  .why_us__items .item {
    padding: 30px;
  }
  .why_us__items .item-image {
    margin-right: 30px;
  }
  .why_us__items .title-item {
    font-size: 18px;
  }
}
@media (max-width: 1439.98px) {
  .why_us {
    padding-top: 72px;
    padding-bottom: 109px;
  }
  .why_us__wrap {
    flex-direction: column;
  }
  .why_us__left {
    margin-right: 0;
    margin-bottom: 43px;
    max-width: 100%;
  }
}
@media (max-width: 991.98px) {
  .why_us__title {
    font-size: 36px;
    letter-spacing: -0.72px;
  }
  .why_us__subtitle {
    margin-bottom: 38px;
    font-size: 16px;
  }
  .why_us__first-description, .why_us__second-description {
    font-size: 16px;
  }
  .why_us__items .item {
    padding-left: 0;
    width: 100%;
    border-right: 0;
  }
  .why_us__items .item:first-child {
    border-top: 0;
  }
  .why_us__items .item:last-child {
    border-bottom: 1px solid #363636;
  }
  .why_us__items .item-image {
    margin-right: 21px;
    width: 80px;
    height: 80px;
  }
}
.about_us {
  padding-top: 141px;
  padding-bottom: 32px;
  background-color: #f3f3f3;
}
.about_us__top {
  margin-bottom: 73px;
}
.about_us__top-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.about_us__top-info svg {
  margin-left: auto;
  margin-right: 38px;
  user-select: none;
}
.about_us__subtitle {
  margin-bottom: 9px;
  color: rgba(21, 21, 21, 0.4);
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 1;
  letter-spacing: -0.4px;
  text-transform: uppercase;
}
.about_us__title {
  color: #151515;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 80px;
  line-height: 1;
  letter-spacing: -1.6px;
}
.about_us .container {
  max-width: 1811px;
}
.about_us .btn {
  padding: 20px 40px;
  color: #f3f3f3;
  border-radius: 100px;
}
.about_us .btn:hover {
  color: #151515;
}
.about_us .accordion {
  padding-right: 40px;
}
.about_us .accordion-item {
  padding-top: 60px;
  padding-bottom: 30px;
  background-color: #f3f3f3;
  border-top: 1px solid rgba(21, 21, 21, 0.2);
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  --bs-accordion-active-bg: none;
}
.about_us .accordion-item:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.about_us .accordion-item:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.about_us .number-item {
  margin-right: 94px;
  color: #151515;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.2;
  user-select: none;
}
.about_us .accordion-header {
  display: flex;
}
.about_us .accordion-button {
  padding: 0 0 30px;
  justify-content: space-between;
  color: #151515;
  background-color: #f3f3f3;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 44px;
  line-height: 1.2;
  letter-spacing: -1.32px;
  user-select: none;
  box-shadow: none;
}
.about_us .accordion-button::after {
  display: none;
}
.about_us .accordion-button .accordion-item-plus svg path:nth-of-type(2) {
  animation: rotateBackward 0.5s ease forwards;
}
.about_us .accordion-button.collapsed .accordion-item-plus svg path:nth-of-type(2) {
  animation: rotateForward 0.5s ease forwards;
}
@keyframes rotateForward {
  0% {
    transform: translate(0, 0) rotate(0);
  }
  100% {
    transform: translate(100%, 0) rotate(90deg);
  }
}
@keyframes rotateBackward {
  0% {
    transform: translate(100%, 0) rotate(90deg);
  }
  100% {
    transform: translate(0, 0) rotate(0);
  }
}
.about_us .accordion-item-plus {
  flex-shrink: 0;
  width: 169px;
  display: flex;
  justify-content: flex-end;
}
.about_us .accordion-collapse {
  padding-right: 169px;
}
.about_us .accordion-body {
  margin-left: 118px;
  padding: 0;
  color: rgba(21, 21, 21, 0.6);
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.4;
}
.about_us .accordion-body ul,
.about_us .accordion-body ol {
  margin-left: 40px;
}
.about_us .accordion-body ul li,
.about_us .accordion-body ol li {
  list-style: disc;
}
.about_us .accordion-body ol li:not(:last-child),
.about_us .accordion-body ul li:not(:last-child) {
  margin-bottom: 10px;
}

@media (max-width: 1239.98px) {
  .about_us {
    padding-top: 44px;
    padding-bottom: 62px;
  }
  .about_us__subtitle {
    font-size: 18px;
  }
  .about_us__title {
    font-size: 60px;
  }
  .about_us__top-info svg {
    margin-top: 28px;
  }
  .about_us .accordion-item {
    padding-top: 30px;
    padding-bottom: 28px;
  }
  .about_us .accordion-button {
    padding: 0 0 12px;
    font-size: 32px;
  }
  .about_us .accordion-body {
    margin-left: 58px;
    font-size: 18px;
  }
  .about_us .number-item {
    margin-right: 44px;
  }
}
@media (max-width: 991.98px) {
  .about_us {
    background-color: #fff;
  }
  .about_us__subtitle {
    font-size: 16px;
    letter-spacing: -0.32px;
  }
  .about_us__title {
    font-size: 36px;
    letter-spacing: -0.72px;
  }
  .about_us__top {
    margin-bottom: 30px;
  }
  .about_us__top-info svg {
    display: none;
  }
  .about_us .accordion-item {
    background-color: #fff;
    padding-top: 30px;
    padding-bottom: 28px;
  }
  .about_us .accordion-button {
    background-color: #fff;
    font-size: 24px;
    letter-spacing: -0.72px;
  }
  .about_us .accordion-body {
    margin-left: 0;
    font-size: 16px;
  }
  .about_us .accordion-body ul,
  .about_us .accordion-body ol {
    margin-left: 25px;
  }
  .about_us .number-item {
    display: none;
  }
  .about_us .accordion-item-plus {
    width: 65px;
  }
  .about_us .accordion-item-plus svg {
    width: 50px;
  }
  .about_us .accordion-collapse {
    padding-right: 0;
  }
}
@media (max-width: 767.98px) {
  .about_us__button {
    margin-left: 10px;
    margin-right: 10px;
  }
  .about_us__button .btn {
    margin-left: auto;
    margin-right: auto;
  }
  .about_us .accordion {
    padding-right: 0;
  }
  .about_us .accordion .accordion-item:first-child {
    padding-top: 0;
    border-top: 0;
  }
}