/* Footer start */

footer {
  padding-top: 30px;
  padding-bottom: 10px;
  flex: 1 1 auto;
  color: #fff;
  background-color: #141414;
  font-family: 'Manrope', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3;

  a {
    margin-top: 7px;
    margin-bottom: 7px;
    padding: 14px 40px;
    display: inline-block;
    color: #fff;
    background-color: #3d3d3d;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.3;
    border-radius: 134px;
    white-space: nowrap;
    overflow: hidden;
    -webkit-transition: color 0.3s ease 0s, background-color 0.3s ease 0s;
    -o-transition: color 0.3s ease 0s, background-color 0.3s ease 0s;
    transition: color 0.3s ease 0s, background-color 0.3s ease 0s;

    &:hover {
      color: #fff;
      background-color: #7e7e7e;
    }
  }

  .phone {
    line-height: 1.4;
  }
}

.footer-logo {
  margin-top: 120px;
  margin-bottom: 80px;
}

.footer__row {
  padding-top: 40px;
  padding-bottom: 40px;
  border-top: 2px solid #525252;

  &.bottom {

    .footer__columns {
      padding-right: 55px;
      justify-content: space-between;
      color: #a9a9a9;
    }

    a {
      margin-top: 0;
      margin-bottom: 0;
      padding: 0;
      display: unset;
      color: #a9a9a9;
      background-color: transparent;
      line-height: 1;
      border-radius: 0;
    }

    a:hover {
      color: #fff;
      background-color: transparent;
    }
  }
}

.footer__columns {
  display: flex;
  align-items: center;

  .footer__column:first-child {
    width: 200px;
    flex-shrink: 0;
  }
}

.footer__column {

  &-title {
    margin-right: 40px;
    color: #a9a9a9;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.3;
  }

  a:not(:last-child) {
    margin-right: 14px;
  }
}

.footer-messenger {
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style-type: none;
  user-select: none;


  .footer-item {

    &:not(:last-child) {
      margin-right: 14px;
    }

    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .footer-item a img {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }

  .footer-item a svg,
  .footer-item a img  {
    margin-right: 14px;
  }
}

/* Footer end */