/* Button start */

.btn {
  padding: 40px 20px;
  height: 92px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #151515;
  font-family: 'Manrope', sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.2;
  border-radius: 26px;
  border: 1px solid #151515;
  word-wrap: break-word;
  white-space: nowrap;
  user-select: none;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    color: #151515;
    background-color: transparent;
    border-color: #151515;
  }
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  color: #151515;
  background-color: transparent;
  border-color: #151515;
}

/* Button end */