.hits {
  position: relative;
  padding-top: 101px;
  padding-bottom: 198px;
  background-color: #1e1e1e;

  &__top {
    margin-bottom: 73px;
    display: flex;
    justify-content: space-between;
  }

  &__top-left {
    display: flex;
  }

  &__top-left svg {
    margin: 71px 84px 0 40px;
    flex-shrink: 0;
  }

  &__title {
    margin-bottom: 26px;
    max-width: 600px;
    color: #fff;
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 80px;
    line-height: 1.4;
    letter-spacing: -1.6px;
  }

  &__subtitle {
    color: #fff;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.5;
    letter-spacing: -0.6px;
  }

  &__top-right {
    margin-left: auto;
    margin-right: auto;
    padding-left: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__right-total {
    margin-bottom: 54px;
    color: rgba(255, 255, 255, 0.60);
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.2;
  }

  &__right-values {
    display: flex;
  }

  &__right-col {
    margin-right: 99px;
  }

  &__right-value {
    color: #f3f3f3;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 48px;
    line-height: 1.2;
  }

  &__right-text {
    color: #fff;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.2;
  }

  .container {
    position: relative;
    max-width: 1850px;
  }

  .hits-logo {
    position: absolute;
    top: 136px;
    right: 0;
    user-select: none;
    pointer-events: none;
  }

  .accordion-item {
    position: relative;
    padding: 0 40px 40px;
    background-color: transparent;
    border-top: 1px solid transparent;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    border-radius: 20px;
    overflow: hidden;
    --bs-accordion-active-bg: none;
  }

  .accordion-item::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 100%;
    user-select: none;
    pointer-events: none;
    transition: opacity 0.3s ease 0s;
    opacity: 0;
  }

  .accordion-item:nth-of-type(1)::after {
    background: url('/image/template/hits-SCM-bg.png') no-repeat;
  }

  .accordion-item:nth-of-type(2)::after {
    background: url('/image/template/hits-AI-bg.png') no-repeat;
  }

  .accordion-item.active::after,
  .accordion-item:hover::after {
    opacity: 1;
  }

  .accordion-item.active {
    padding-top: 40px;
    border-top: 1px solid transparent;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
  }

  .accordion-item:first-of-type {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .accordion-item:last-of-type {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .number-item {
    margin-right: 94px;
    color: #fff;
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.2;
  }

  .accordion-header {
    position: relative;
    padding-top: 40px;
    display: flex;
    border-top: 1px solid #494949;
    z-index: 1;
  }

  .accordion-item.active .accordion-header {
    padding-top: 0;
    border-top: 0;
  }

  .accordion-button-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .accordion-button-left {
    max-width: 635px;
    width: 100%;
  }

  .accordion-buttons {
    margin: 22px -6px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .accordion-buttons a {
    margin: 6px;
    padding: 9px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1e1e1e;
    background: linear-gradient(90deg, #89f7fe 0%, #66a6ff 100%);
    border-radius: 40px;
    border: 1px solid rgba(255, 255, 255, 0.20);
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.2;
    user-select: none;
    white-space: nowrap;
  }

  .accordion-buttons .btn {
    margin: 6px;
    padding: 5px 24px 4px 8px;
    height: auto;
    color: #fff;
    border-radius: 40px;
    border: 1px solid rgba(255, 255, 255, 0.20);
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.2;
    user-select: none;
    white-space: nowrap;
  }

  .accordion-buttons .btn svg {
    margin-right: 12px;
    flex-shrink: 0;
  }

  .accordion-button-right {
    width: 100%;
    display: flex;
  }

  .accordion-button {
    padding: 0 20px 0;
    background-color: transparent;
    user-select: none;
    box-shadow: none;
  }

  .accordion-button::after {
    display: none;
  }

  .accordion-item-circle {
    margin-left: auto;
    margin-right: auto;
    width: 190px;
    height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: rgba(243, 243, 243, 0.10);
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.2;
    border-radius: 50%;
    backdrop-filter: blur(50px);
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    overflow: hidden;
  }

  .accordion-button.collapsed .accordion-item-circle {
    background-color: #7e7e7e;
    backdrop-filter: none;
  }

  .accordion-title {
    color: #fff;
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 48px;
    line-height: 1.2;
    letter-spacing: -1.44px;
  }

  .accordion-subtitle {
    color: #fff;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.5;
    letter-spacing: -0.6px;
  }

  .accordion-collapse {
    position: relative;
    padding-right: 169px;
    z-index: 1;
  }

  .accordion-body {
    padding: 60px 0 0;
    color: rgba(255, 255, 255, 0.60);
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.2;
  }

  .accordion-body ul,
  .accordion-body ol {
    margin-left: 40px;
  }

  .accordion-body ul li,
  .accordion-body ol li {
    list-style: disc;
  }

  .accordion-body ol li:not(:last-child),
  .accordion-body ul li:not(:last-child) {
    margin-bottom: 10px;
  }
}

@media (max-width: 1365.98px) {
  .hits {

    &__title {
      font-size: 60px;
    }

    .accordion-title {
      font-size: 36px;
    }

    .accordion-subtitle {
      font-size: 20px;
      letter-spacing: -0.48px;
    }

    .accordion-buttons a,
    .accordion-buttons .btn {
      font-size: 16px;
    }

    .accordion-body {
      font-size: 16px;
    }
  }
}

@media (max-width: 1049.98px) {
  .hits {
    padding-top: 39px;
    padding-bottom: 70px;

    &__title {
      font-size: 36px;
      line-height: 1.1;
      letter-spacing: -0.72px;
    }

    &__top {
      margin-bottom: 26px;
    }

    &__top-right,
    &__top-left svg,
    .number-item {
      display: none;
    }

    .accordion-item,
    .accordion-item.active {
      padding-top: 0;
      padding-left: 10px;
      padding-right: 10px;
    }

    .accordion-item.active {
      padding-bottom: 20px;
      border-top: 0;
    }

    .accordion-item {
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 0;
      border: 0;
      border-radius: 0;
      overflow: unset;
    }

    .accordion-item-circle {
      margin-right: unset;
      width: 100px;
      height: 100px;
      font-size: 14px;
    }

    .accordion-item:first-of-type {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .accordion-item:last-of-type {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    .accordion-item::after {
      height: 280px;
      background-size: cover;
      background-position: center;
    }

    .accordion-item:nth-of-type(1)::after,
    .accordion-item:nth-of-type(2)::after {
      background-size: cover;
      background-position: center;
    }

    .accordion-header,
    .accordion-item.active .accordion-header {
      margin-bottom: 60px;
      padding-top: 79px;
      padding-bottom: 32px;
    }

    .accordion-title {
      font-size: 24px;
      letter-spacing: -0.72px;
    }

    .accordion-subtitle {
      font-size: 16px;
    }

    .accordion-body {
      padding-top: 0;
    }

    .accordion {
      margin-left: -10px;
      margin-right: -10px;
    }

    .accordion-collapse {
      padding-right: 0;
    }

    .accordion-buttons {
      flex-direction: column;
      align-items: flex-start;
    }

    .accordion-buttons .btn {
      max-width: 270px;
    }

    .accordion-button {
      position: absolute;
      right: -18px;
      bottom: -38px;
    }

    .accordion-button-left {
      padding-right: 100px;
      max-width: 100%;
    }

    .accordion-button-right {
      width: auto;
    }
  }
}

@media (max-width: 687.98px) {
  .hits {

    .accordion-item::after {
      height: 305px;
    }
  }
}

@media (max-width: 436.98px) {
  .hits {

    .accordion-item::after {
      height: 330px;
    }
  }
}

@media (max-width: 349.98px) {
  .hits {

    .accordion-buttons a,
    .accordion-buttons .btn {
      height: 44px;
    }

    .accordion-buttons .btn {
      padding: 5px 8px;
    }

    .accordion-buttons .btn svg {
      display: none;
    }
  }
}