.why_us {
  padding-top: 150px;
  padding-bottom: 150px;
  background-color: #151515;

  &__wrap {
    display: flex;
  }

  &__left {
    margin-right: 115px;
    max-width: 436px;
    flex-shrink: 0;
  }

  &__title {
    margin-bottom: 30px;
    color: #fff;
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 64px;
    line-height: 1.2;
    letter-spacing: -1.28px;
  }

  &__subtitle {
    margin-bottom: 91px;
    color: #f3f3f3;
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 32px;
    line-height: 1.2;
  }

  &__first-description,
  &__second-description {
    display: flex;
    color: #f3f3f3;
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.2;
  }

  &__first-description {
    margin-bottom: 40px;
  }

  &__description-number {
    margin-right: 17px;
    color: #fff;
    font-family: 'Manrope', sans-serif;
    font-weight: 800;
    font-size: 20px;
    line-height: 1.2;
  }

  &__description {}

  &__items {
    display: flex;
    flex-wrap: wrap;

    .item {
      padding: 60px;
      width: 50%;
      display: flex;
      align-items: center;
      border-top: 1px solid #363636;
      border-right: 1px solid #363636;
    }

    .item:nth-of-type(odd) {
      padding-left: 0;
    }

    .item:nth-of-type(even) {
      border-right: 0;
    }

    .item-image {
      margin-right: 40px;
      width: 110px;
      height: 110px;
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      background-color: #262626;
      border-radius: 50%;
      user-select: none;
      pointer-events: none;
      overflow: hidden;
    }

    .information-box {}

    .title-item {
      color: #fff;
      font-family: 'Manrope', sans-serif;
      font-weight: 600;
      font-size: 20px;
      line-height: 1.2;
    }
  }

  .container {
    max-width: 1770px;
  }
}

@media (max-width: 1599.98px) {
  .why_us {

    &__left {
      margin-right: 75px;
    }

    &__title {
      font-size: 54px;
    }

    &__subtitle {
      margin-bottom: 48px;
      font-size: 26px;
    }

    &__first-description,
    &__second-description {
      font-size: 18px;
    }

    &__first-description {
      margin-bottom: 34px;
    }

    &__items {

      .item {
        padding: 30px;
      }

      .item-image {
        margin-right: 30px;
      }

      .title-item {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 1439.98px) {
  .why_us {
    padding-top: 72px;
    padding-bottom: 109px;

    &__wrap {
      flex-direction: column;
    }

    &__left {
      margin-right: 0;
      margin-bottom: 43px;
      max-width: 100%;
    }
  }
}

@media (max-width: 991.98px) {
  .why_us {

    &__title {
      font-size: 36px;
      letter-spacing: -0.72px;
    }

    &__subtitle {
      margin-bottom: 38px;
      font-size: 16px;
    }

    &__first-description,
    &__second-description {
      font-size: 16px;
    }

    &__items {

      .item {
        padding-left: 0;
        width: 100%;
        border-right: 0;
      }

      .item:first-child {
        border-top: 0;
      }

      .item:last-child {
        border-bottom: 1px solid #363636;
      }

      .item-image {
        margin-right: 21px;
        width: 80px;
        height: 80px;
      }
    }
  }
}