.implementation {
  padding-top: 150px;
  padding-bottom: 150px;
  background-color: #f3f3f3;

  &__top {
    margin-bottom: 138px;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    max-width: 972px;
    color: #151515;
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 80px;
    line-height: 1;
    letter-spacing: -2.4px;
  }

  &__title svg {
    display: none;
  }

  &__subtitle-wrap {
    display: flex;
  }

  &__subtitle {
    margin-right: 102px;
    max-width: 308px;
    color: #151515;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.2;
  }

  &__image {
    flex-shrink: 0;
  }

  &__image-mob {
    display: none;
  }

  &__bottom {
    display: flex;
  }

  &__bottom-left,
  &__bottom-right {
    color: #151515;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 32px;
    line-height: 1;
  }

  &__bottom-left {
    width: 66%;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;

    .item {
      padding: 60px;
      width: 50%;
      display: flex;
      align-items: center;
      border-top: 1px solid #c7c7c7;
      border-right: 1px solid #c7c7c7;
    }

    .item:nth-of-type(odd) {
      padding-left: 0;
    }

    .item-number {
      margin-right: 40px;
      padding-top: 7px;
      width: 110px;
      height: 110px;
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      color: #000;
      background-color: #e9e9e9;
      font-family: 'Manrope', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 1;
      border-radius: 50%;
      white-space: nowrap;
      user-select: none;
      pointer-events: none;
      overflow: hidden;
    }

    .item-number span {
      margin: 0 2px 17px;
      font-weight: 700;
      font-size: 40px;
    }
  }

  &__bottom-right {
    padding-top: 60px;
    padding-left: 60px;
    width: 34%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-top: 1px solid #c7c7c7;
  }

  &__bottom-number {
    margin-bottom: 90px;
    color: #000;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 48px;
    line-height: 1;
    white-space: nowrap;
  }

  &__bottom-number span {
    color: #fd512f;
    font-weight: 700;
    font-size: 200px;
  }

  &__bottom-description p {
    margin-bottom: 0;
  }

  .container {
    max-width: 1770px;
  }
}

@media (max-width: 1599.98px) {
  .implementation {

    &__top {
      margin-bottom: 100px;
    }

    &__items {

      .item {
        padding: 30px;
      }

      .item-number {
        margin-right: 30px;
      }

      .title-item {
        font-size: 18px;
      }
    }

    &__bottom-right {
      padding-top: 30px;
      padding-left: 30px;
    }

    &__bottom-number {
      margin-bottom: 40px;
      font-size: 34px;
    }

    &__bottom-number span {
      font-size: 120px;
    }
  }
}

@media (max-width: 1365.98px) {
  .implementation {

    &__title {
      max-width: 100%;
    }

    &__subtitle {
      margin-right: 42px;
      font-size: 20px;
    }
  }
}

@media (max-width: 991.98px) {
  .implementation {
    padding-top: 65px;
    padding-bottom: 45px;
    background-color: #fff;

    &__top {
      margin-bottom: 0;
      flex-direction: column;
    }

    &__title-wrap {
      display: flex;
      justify-content: space-between;
    }

    &__title {
      margin-bottom: 15px;
      padding-right: 20px;
      display: flex;
      justify-content: space-between;
    }

    &__title svg {
      margin-left: 20px;
      display: block;
      flex-shrink: 0;
    }

    &__image {
      display: none;
    }

    &__image-mob {
      display: block;
    }

    &__subtitle-wrap {
      margin-bottom: 25px;
    }

    &__subtitle {
      margin-right: 0;
    }

    &__bottom {
      flex-direction: column;
    }

    &__bottom-left {
      width: 100%;
    }

    &__items {

      .item {
        padding: 20px;
      }

      .item:nth-of-type(even) {
        border-right: 0;
      }
    }

    &__bottom-right {
      padding-left: 0;
      width: 100%;
      align-items: center;
    }
  }
}

@media (max-width: 767.98px) {
  .implementation {

    &__title {
      font-size: 36px;
      letter-spacing: -1.08px;
    }

    &__subtitle {
      font-size: 16px;
    }

    &__bottom {
      flex-direction: column;
    }

    &__bottom-left {
      width: 100%;
    }

    &__items {

      .item {
        width: 100%;
      }

      .item:nth-of-type(odd) {
        border-right: 0;
      }

      .item:nth-of-type(even) {
        padding-left: 0;
      }

      .title-item {
        font-size: 16px;
      }
    }

    &__bottom-right {
      padding-left: 0;
      width: 100%;
      align-items: center;
      text-align: center;
    }

    &__bottom-number {
      margin-bottom: 27px;
      font-size: 16px;
    }

    &__bottom-number span {
      font-size: 96px;
    }
  }
}