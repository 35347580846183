@supports (overflow: clip) {
  .wrapper {
    overflow: clip;
  }
}

@media (min-width: 992px) {
  header .phone-number,
  .header__burger-btn,
  .header__burger-menu-wrap,
  .header__burger-menu__img-mob,
  .header__burger-menu__second-img-mob {
    display: none;
  }
}

@media (max-width: 1239.98px) {
  .btn {
    margin-top: 30px;
    padding: 20px 40px;
    max-width: 370px;
    width: 100%;
    height: 60px;
    font-size: 20px;
    border-radius: 100px;
  }
}

@media (max-width: 1199.98px) {
  .wrapper {
    // padding-top: 70px;
    padding-top: 0;
  }

  header {
    height: 70px;

    #logo img {
      max-width: 210px;
      width: 100%;
    }

    a {
      padding: 12px 20px;
      font-size: 16px;
    }
  }

  .header__language {
    margin-right: 20px;
  }
}

@media (max-width: 991.98px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .breadcrumb,
  .pagination {
    justify-content: unset;
  }

  .product-grid {
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
  }

  .pagination>li>a,
  .pagination>li>span {
    min-width: 32px;
    height: 32px;
    font-size: 14px;
  }

  header #logo {
    padding-right: 10px;
  }

  .header__burger-btn {
    flex-shrink: 0;
    background-color: transparent;
  }

  .header__burger-menu {
    position: fixed;
    top: 0;
    left: 0;
    max-width: 350px;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-flow: column;
    transform: translateX(-100%);
    transition: transform .3s ease-out;
    overflow: auto;
    z-index: 110;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url('/image/template/menu-burger-bg.png') no-repeat;
      background-position-x: center;
      background-position-y: bottom;
      user-select: none;
      pointer-events: none;
    }

    &.active {
      transform: translateX(0);
    }
  }

  .header__burger-close-wrap {
    margin-left: auto;
  }

  .header__burger-close {
    width: 46px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }

  .header__burger-menu-wrap.active {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #00000066;
    display: block;
    transition: background 0.3s;
    z-index: 51;
  }

  .header__burger-logo {
    margin: 20px auto 40px;
  }

  .header__burger-menu__img-mob {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    user-select: none;
    pointer-events: none;
  }

  .header__burger-menu__second-img-mob {
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 100%;
    pointer-events: none;

    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background: url('/image/templates/cake-menu-bg.png') no-repeat;
      background-position-x: right;
      background-position-y: bottom;
      background-size: 100%;
      user-select: none;
      pointer-events: none;
    }
  }

  .header__menu-wrap {
    flex-direction: column-reverse;
  }

  .header__nav ul {
    flex-direction: column;
  }

  .dropdown-menu.show {
    display: block;
    // top: -58px !important;
    left: 0 !important;
  }

  .header__language {
    position: relative;
    margin-right: 0;
    z-index: 1;
  }

  .btn-language-menu ul a {
    padding: 10px 20px;
    color: #1e1e1e;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: normal;
    border-radius: 30px;
    backdrop-filter: blur(20px);
    letter-spacing: -0.4px;
    white-space: nowrap;
    overflow: hidden;
  }

  .btn-language-menu ul a.activeLanguage {
    background: rgba(30, 30, 30, 0.06);
  }

  .header__nav {
    position: relative;
    margin-bottom: 60px;
    z-index: 1;
  }

  .header__nav a {
    margin-top: 6px;
    margin-bottom: 6px;
    padding: 10px 20px;
    width: 200px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #1c355b;
    border-radius: 26px;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
  }

  .header__nav .dropdown-menu {
    position: relative !important;
    top: 0 !important;
    padding: 0;
    transition: all 0.3s;
  }

  .header__nav .dropdown-menu a {
    background-color: #7399d3 !important;
  }
}

@media (max-width: 767.98px) {
  .header__nav .dropdown-menu {
    top: -58px !important;
  }
}

@media (max-width: 649.98px) {
  footer {

    img {
      margin-left: auto;
      margin-right: auto;
    }

    a {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 16px;
      line-height: 1.7;
    }

    .footer__row:nth-of-type(2) .footer__columns {
      border-top: 0;
    }
  }

  .footer-logo {
    margin-top: 0;
    margin-bottom: 0;
  }

  .footer__row {
    padding-top: 25px;
    padding-bottom: 0;
    border-top: 0;
  }

  .footer__columns {
    padding-top: 26px;
    flex-direction: column;
    align-items: flex-start;
    border-top: 1px solid #3D3D3D;
  }

  .footer__column-title {
    margin-right: 0;
  }

  .footer-messenger {
    margin: 0;
  }

  .footer__columns .footer__column:first-child {
    margin-bottom: 17px;
    width: 100%;
  }

  .footer__column a:not(:last-child) {
    margin-right: 7px;
  }

  .footer__row.bottom .footer__columns {
    padding-top: 38px;
    padding-right: 0;
    padding-bottom: 44px;
  }

  .footer-messenger .footer-item:not(:last-child) {
    margin-right: 7px;
  }

  .footer__row.bottom .footer__columns ul {
    margin-bottom: 13px;
  }
}

@media (max-width: 389.98px) {
  header a {
    font-size: 14px;
  }

  .header-informations li a {
    font-size: 14px;
  }

  footer {

    a {
      font-size: 14px;
    }
  }
}

@media (max-width: 349.98px) {
  header #logo img {
    max-width: 180px;
  }
}