.about_us {
  padding-top: 141px;
  padding-bottom: 32px;
  background-color: #f3f3f3;

  &__top {
    margin-bottom: 73px;
  }

  &__top-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__top-info svg {
    margin-left: auto;
    margin-right: 38px;
    user-select: none;
  }

  &__subtitle {
    margin-bottom: 9px;
    color: rgba(21, 21, 21, 0.40);
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 1;
    letter-spacing: -0.4px;
    text-transform: uppercase;
  }

  &__title {
    color: #151515;
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 80px;
    line-height: 1;
    letter-spacing: -1.6px;
  }

  .container {
    max-width: 1811px;
  }

  .btn {
    padding: 20px 40px;
    color: #f3f3f3;
    border-radius: 100px;
  }

  .btn:hover {
    color: #151515;
  }

  .accordion {
    padding-right: 40px;
  }

  .accordion-item {
    padding-top: 60px;
    padding-bottom: 30px;
    background-color: #f3f3f3;
    border-top: 1px solid rgba(21, 21, 21, .2);
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    --bs-accordion-active-bg: none;
  }

  .accordion-item:first-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .accordion-item:last-of-type {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .number-item {
    margin-right: 94px;
    color: #151515;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.2;
    user-select: none;
  }

  .accordion-header {
    display: flex;
  }

  .accordion-button {
    padding: 0 0 30px;
    justify-content: space-between;
    color: #151515;
    background-color: #f3f3f3;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 44px;
    line-height: 1.2;
    letter-spacing: -1.32px;
    user-select: none;
    box-shadow: none;
  }

  .accordion-button::after {
    display: none;
  }

  .accordion-button .accordion-item-plus svg path:nth-of-type(2) {
    animation: rotateBackward 0.5s ease forwards;
  }

  .accordion-button.collapsed .accordion-item-plus svg path:nth-of-type(2) {
    animation: rotateForward 0.5s ease forwards;
  }

  @keyframes rotateForward {
    0% {
      transform: translate(0, 0) rotate(0);
    }

    100% {
      transform: translate(100%, 0) rotate(90deg);
    }
  }

  @keyframes rotateBackward {
    0% {
      transform: translate(100%, 0) rotate(90deg);
    }

    100% {
      transform: translate(0, 0) rotate(0);
    }
  }

  .accordion-item-plus {
    flex-shrink: 0;
    width: 169px;
    display: flex;
    justify-content: flex-end;
  }

  .accordion-collapse {
    padding-right: 169px;
  }

  .accordion-body {
    margin-left: 118px;
    padding: 0;
    color: rgba(21, 21, 21, 0.60);
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.4;
  }

  .accordion-body ul,
  .accordion-body ol {
    margin-left: 40px;
  }

  .accordion-body ul li,
  .accordion-body ol li {
    list-style: disc;
  }

  .accordion-body ol li:not(:last-child),
  .accordion-body ul li:not(:last-child) {
    margin-bottom: 10px;
  }
}

@media (max-width: 1239.98px) {
  .about_us {
    padding-top: 44px;
    padding-bottom: 62px;

    &__subtitle {
      font-size: 18px;
    }

    &__title {
      font-size: 60px;
    }

    &__top-info svg {
      margin-top: 28px;
    }

    .accordion-item {
      padding-top: 30px;
      padding-bottom: 28px;
    }

    .accordion-button {
      padding: 0 0 12px;
      font-size: 32px;
    }

    .accordion-body {
      margin-left: 58px;
      font-size: 18px;
    }

    .number-item {
      margin-right: 44px;
    }
  }
}

@media (max-width: 991.98px) {
  .about_us {
    background-color: #fff;

    &__subtitle {
      font-size: 16px;
      letter-spacing: -0.32px;
    }

    &__title {
      font-size: 36px;
      letter-spacing: -0.72px;
    }

    &__top {
      margin-bottom: 30px;
    }

    &__top-info svg {
      display: none;
    }

    .accordion-item {
      background-color: #fff;
      padding-top: 30px;
      padding-bottom: 28px;
    }

    .accordion-button {
      background-color: #fff;
      font-size: 24px;
      letter-spacing: -0.72px;
    }

    .accordion-body {
      margin-left: 0;
      font-size: 16px;
    }

    .accordion-body ul,
    .accordion-body ol {
      margin-left: 25px;
    }

    .number-item {
      display: none;
    }

    .accordion-item-plus {
      width: 65px;
    }

    .accordion-item-plus svg {
      width: 50px;
    }

    .accordion-collapse {
      padding-right: 0;
    }
  }
}

@media (max-width: 767.98px) {
  .about_us {

    &__button {
      margin-left: 10px;
      margin-right: 10px;
    }

    &__button .btn {
      margin-left: auto;
      margin-right: auto;
    }

    .accordion {
      padding-right: 0;
    }

    .accordion .accordion-item:first-child {
      padding-top: 0;
      border-top: 0;
    }
  }
}